/* large desktop :1366px. */
@media (min-width: 1200px) and (max-width: 1500px) {

}


/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1199px) {
  .shorting-style select {
    width: 210px;
  }
  .product_dynamic_btn {
    width: 70%;
  }
  .description-review-topbar a {
    margin: 0 35px 15px;
  }
  .product-details.pd_sticky {
    padding-bottom: 80px;
  }
  .p_content_2::before {
    left: -22px;
  }
  .sidebar-blog {
    margin-bottom: 40px !important;
  }
  .slider-content.slider-animated-1.trans_header {
    padding-top: 280px;
  }
  .header-search form input {
    width: 250px !important;
  }
  .header-position .devita_plr_40 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .product-overview-content h2 {
    font-size: 55px !important;
  }
  .shoe-header .header-contact-search-wrapper.f-right.d-flex.flex-wrap.w_header {
    justify-content: space-around;
  }




}


/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
  .header-search form input {
/*    width: 300px !important; */
  }
  .img_box_1 img.full-width {
    max-width: 100%;
  }
  .shorting-style select {
    width: 210px;
  }
  .p_sidebar_wrapper .shop-sidebar-wrapper, .shop-sidebar-wrapper {
    margin-top: 40px;
  }
  .description-review-topbar a {
    margin: 0 35px 15px;
  }
  .quality-add-to-cart .product-action > a.action-cart {
    padding: 11px 72px 10px;
  }
  .pro-details-gallery-mrg {
    margin-top: 0;
    margin-bottom: 50px;
  }
  .quality label {
    margin: 0 6px 0 0;
  }
  .quality {
    margin: 0 5px 0 0;
  }
  .dynamic_btn_sticky .product_dynamic_btn {
    width: 100%;
  }
  .product-details.pd_sticky {
    padding-bottom: 50px;
  }
  .blog-side-md {
    margin-bottom: 0;
    margin-top: 30px;
  }
  .landing_prod_banner {
    padding-bottom: 100px !important;
  }
  .p_content_2::before {
    opacity: 0;
  }
  .p_content_wrapper {
    text-align: center;
  }
  .p_content_2 {
    margin-bottom: 60px;
  }
  .pro-info-area-2 {
    padding-bottom: 50px !important;
  }
  .feat-content {
    margin-top: 30px;
  }
  .feat-content:first-child {
    margin-top: 0;
  }
  .feat-main_img {
    margin: 50px 0;
  }
  .sidebar-blog {
    margin-bottom: 40px !important;
  }
  .devita_plr_40 {
    padding: 10px;
  }
  .header-position .devita_plr_40 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .header-position .logo.mobile-logo {
    padding: 15px 0;
  }  
  .slider-content.slider-animated-1.trans_header {
    padding-top: 280px;
  }
  .mean-container a.meanmenu-reveal {
    border: 1px solid #000;
    color: #000;
  }
  .mean-container a.meanmenu-reveal span {
    background: #000 none repeat scroll 0 0;
  }
  .blog-wrapper-3.mb-30 {
    margin-bottom: 0;
  }
  .product-overview-content h2 {
    font-size: 26px !important;
  }
  .slider-content.slider-animated-1 {
    margin-right: 0 !important;
  }
  .watch-mean-menu .mean-container a.meanmenu-reveal span {
    background: #fff none repeat scroll 0 0;
  }
  .watch-mean-menu .mean-container a.meanmenu-reveal {
    border: 1px solid #fff;
    color: #fff;
  }
  .product-details-img {
    text-align: center;
  }
  .mrg-top-md {
    margin-top: 0;
  }
  .footer-bottom {
    text-align: center;
  }
  .payment-icon.f-right {
    margin-top: 15px;
  }
  .slider-content-12.slider-animated-1 {
    margin-left: 40px;
  }
  .w_head {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ban_underst_3 .row [class*=col] {
    padding-right: 0 !important;
  }
  .ban_underst_3 .row [class*=col]:nth-child(2) {
    padding-left: 0 !important;
  }
  .ban_underst_3 .row [class*=col]:last-child {
    padding-left: 0 !important;
  }
  .auto-parts-social.social-icon {
    margin-top: 20px;
  }
  .shoe-header .header-contact-search-wrapper.f-right.d-flex.flex-wrap.w_header {
    justify-content: space-around;
  }
  .header-bottom .hbottom_border .logo.mobile-logo {
    padding-top: 30px;
  }
  .header-middle .pt-40.pb-40 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .header-position .header-bottom .hbottom_border .logo.mobile-logo {
    padding-top: 0;
  }
  .menu-coffee-color .row div[class^="col-"] {
    padding-bottom: 5px;
  }



}


/* small mobile :320px. */
@media (max-width: 767px) {
  .header-search form input, .header-middle-color-13.header-search form input, .header-search-5 form input {
    width: 175px !important;
  }
  .header-cart.middle-same {
    margin: 0 auto;
  }
  .owl-item.active .slider-animated-1 h2 {
    font-size: 40px !important;
    line-height: 1;
  }
  .owl-item.active .slider-animated-1 h5 {
    font-size: 16px !important;
  }
  .owl-item.active .slider-animated-1 h3 {
    font-size: 40px !important;
  }
  .best-selling-active.nav-style.owl-carousel .owl-nav div {
    display: none;
  }
  .connect-content > h2 {
    font-size: 34px !important;
  }
  .header-middle .d-flex.justify-content-between.align-items-center.flex-wrap {
    justify-content: center !important;
  }
  .qv_modal_body > div {
    display: block;
    float: unset;
    margin: 20px auto;
    width: 100%;
  }
  .img_box_1 img.full-width {
    max-width: 100%;
  }
  .quickview-plus-minus.d-flex {
    display: block !important;
    text-align: center;
  }
  .cart-plus-minus {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .shorting-style select {
    width: 190px;
  }
  .shop-topbar-left {
    display: block;
    align-items: center;
  }
  .shop-topbar-left > p {
    margin: 10px 0 0;
  }
  .shorting-style {
    display: block;
  }
  .p_sidebar_wrapper .shop-sidebar-wrapper, .shop-sidebar-wrapper {
    margin-top: 40px;
  }
  .product-details-content {
    margin-top: 50px;
  }
  .description-review-topbar a {
    margin: 0 8px 12px;
  }
  .quality-add-to-cart .product-action > a.action-cart {
    padding: 11px 80px 10px;
  }
  .product_dynamic_btn, .dynamic_btn_sticky .product_dynamic_btn {
    width: 100%;
  }
  .product-details-gallery {
    text-align: center;
  }
  .zoompro-span {
    margin-bottom: 30px;
  }
  .pro-details-gallery-mrg {
    margin-top: 0;
    margin-bottom: 50px;
  }
  .product-details.pd_sticky {
    padding-bottom: 30px;
  }
  .blog-side-md {
    margin-bottom: 0;
    margin-top: 30px;
  }
  .blog-meta-4 {
    margin: 20px 0 0;
  }
  .contact-information {
    margin-bottom: 20px;
  }
  .dev_address_grid > div {
    width: 100%;
  }
  #google_translate_element {
    display: none;
  }
  .news_style_1 {
    margin-left: 10px;
  }
  .best-selling-active-2.nav-style.owl-carousel .owl-nav div {
    display: none;
  }
  .p_content_2::before {
    opacity: 0;
  }
  .p_content_wrapper {
    text-align: center;
  }
  .p_content_2 {
    margin-bottom: 60px;
  }
  .pro-info-area-2 {
    padding-bottom: 50px !important;
  }
  .landing_prod_banner {
    padding-bottom: 100px !important;
  }
  .feat-content {
    margin-top: 30px;
  }
  .feat-content:first-child {
    margin-top: 0;
  }
  .feat-main_img {
    margin: 50px 0;
  }
  .p_content_wrapper {
    padding: 0 10px;
  }
  .p_content_2 {
    padding: 30px 10px;
  }
  .sidebar-blog {
    margin-bottom: 40px !important;
  }
  .product-slider-nav-2.nav-style.nav-style-res {
    opacity: 0;
  }
  .header-position .header-bottom {
    padding: 0;
  }
  .header-position .logo.mobile-logo {
    padding: 15px 0;
    margin-left: 8px;
  }
  .slider-content.slider-animated-1.trans_header {
    padding-top: 430px;
  }
  .trans_header_area .owl-dot-style .owl-dots {
    display: none;
  }
  .devita_plr_40 {
    padding: 10px;
  }
  .header-position .devita_plr_40 {
    padding-left: 0;
    padding-right: 0;
  }
  .header-position .mean-container a.meanmenu-reveal {
    top: -42px;
  }
  .header-position .mean-container a.meanmenu-reveal {
    right: 10px;
  }
  .header-search form input {
    width: 175px !important;
  }
  .header-search {
    margin: 15px 0 20px;
  }
  .mean-container a.meanmenu-reveal {
    border: 1px solid #000;
    color: #000;
  }
  .mean-container a.meanmenu-reveal span {
    background: #000 none repeat scroll 0 0;
  }
  .header-search.middle-same {
    margin: 20px auto 24px;
    text-align: center;
  }
  .blog-wrapper-3.mb-30 {
    margin-bottom: 0;
  }
  .news_style_2 .subscribe-form-4 form .clear-3 {
    position: relative;
    margin-top: 20px;
    width: 100%;
  }
  .news_style_2 {
    padding: 60px 0 !important;
  }
  .news_style_2 .subscribe-form-4 form input {
    padding: 0 10px;
  }
  .product-overview-content h2 {
    font-size: 30px !important;
  }
  .product-overview-wrapper {
    margin: 10px 0;
  }
  .p_info_1 {
    margin-top: 60px;
  }
  .watch-mean-menu .mean-container a.meanmenu-reveal span {
    background: #fff none repeat scroll 0 0;
  }
  .watch-mean-menu .mean-container a.meanmenu-reveal {
    border: 1px solid #fff;
    color: #fff;
  }  
  .header-setting.mobile_view {
    display: inline-block;
    padding: 0;
    border: navajowhite;
  }
  .header-top-wrapper.watch-header-top .language-currency.lang-curr-mrg {
    margin-top: 10px;
  }
  .header-top-wrapper.watch-header-top .header-cart.watch-cart {
    border-left: none;
  }
  .watch_lang {
    margin-right: 31px;
  }
  .product-details-img {
    text-align: center;
  }
  .slider_area_2_tools .item_container {
    flex-wrap: wrap;
  }
  .slider_area_2_tools .owl-dot-style .owl-dots {
    display: none;
  }
  .slider-content.slider-animated-1 {
    margin-right: 0 !important;
    margin-left: 15px !important;
  }
  .footer-bottom {
    text-align: center;
  }
  .payment-icon.f-right {
    margin-top: 15px;
  }
  .w_head {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .lay_4 .devita_plr_40 {
    padding-left: 0;
    padding-right: 0;
  }
  .owl-item.active .slider-content-underware.slider-animated-1 h2 {
    font-size: 27px !important;
  }
  .ban_underst_3 .row [class*=col] {
    padding-right: 0 !important;
  }
  .ban_underst_3 .row [class*=col]:nth-child(2) {
    padding-left: 0 !important;
  }
  .ban_underst_3 .row [class*=col]:last-child {
    padding-left: 0 !important;
  }
  .slider-area-underwear .pt-210 {
    padding-top: 210px;
  }
  .ban_underwear .banner-btn a.btn-style {
    padding: 10px 25px !important;
  }
  .ban_underwear .banner-btn a.btn-style {
    font-size: 10px !important;
  }
  .ban_underwear.banner-text > h4 {
    font-size: 12px !important;
  }
  .ban_underwear.banner-text > h3 {
    font-size: 15px !important;
  }
  .news_mb {
    padding: 60px 0 !important;
  }
  .services-center-text > h2 {
    font-size: 40px !important;
  }
  .slider-area .owl-dots, .slider-banner-area .owl-dots {
    display: none !important;
  }
  .auto-parts-social.social-icon {
    margin-top: 20px;
  }
  .header-contact-search-wrapper.f-right.d-flex.flex-wrap.w_header {
    justify-content: center;
  }
  .product-tab-list > a h4 {
    padding: 6px;
    font-size: 15px;
  }
  .header-search {
    margin: 15px 0 20px;
    margin: 20px auto;
  }
  .header-bottom .hbottom_border .logo.mobile-logo {
    padding-top: 30px;
  }
  .header-middle .pt-40.pb-40 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .header-position .header-bottom .hbottom_border .logo.mobile-logo {
    padding-top: 0;
  }
  .menu-coffee-color .row div[class^="col-"] {
    padding-bottom: 5px;
  }


}


/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .product_dynamic_btn, .dynamic_btn_sticky .product_dynamic_btn {
    width: 65%;
  }
  .ban_underwear .banner-btn a.btn-style {
    font-size: 14px !important;
  }
  .ban_underwear.banner-text > h4 {
    font-size: 18px !important;
  }
  .ban_underwear.banner-text > h3 {
    font-size: 24px !important;
  }
  .shopping-cart-content {
    left: auto;
    transform: unset;
  }
  .header-contact-search-wrapper.f-right.d-flex.flex-wrap.w_header .header-search {
    margin: 0 20px;
  }


}
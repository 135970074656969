/* Ratting Icon Start */
.spr-badge-starrating .spr-icon {
  font-size: 11px !important;
  opacity: 1 !important;
  padding: 0 3px 0 0;
}
.spr-badge-caption-none .spr-badge-caption {
  display: none;
}
/* Ratting Icon End */
/* Review App Start */
.cart-main-area textarea, .spr-form-input-text, .spr-form-input-textarea,
.spr-form-contact-email .spr-form-label+.spr-form-input {
  background: transparent;
  border: 1px solid #ddd;
}
.spr-form-input-text:focus, .spr-form-label+.spr-form-input:focus, .spr-form-input-textarea:focus,
.spr-form-contact-email .spr-form-label+.spr-form-input:focus {
  border: 1px solid #050035;
}
.spr-header-title, .spr-form-contact-name, .spr-form-contact-email, .spr-form-contact-location, .spr-form-review-rating, .spr-form-review-title, 
.spr-form-review-body, .spr-form-title {
  text-align: left;
}
.spr-form-input.spr-starrating .spr-icon.spr-icon-star-hover, .spr-form-input.spr-starrating .spr-icon.spr-icon-star,
.spr-form-input.spr-starrating .spr-icon.spr-icon-star.spr-icon-star-empty, h4.grand-totall-title, .blog-content > h4 a:hover, 
.blog-wrapper-2 .blog-meta ul li a:hover {
  color: #f1ac06;
}
.spr-form-input.spr-starrating a.spr-icon {
  font-size: 100%;
  opacity: 1;
  margin-right: 3px;
}
.spr-button.spr-button-primary {
  background-color: #f1ac06;
  border: none;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  height: 42px;
  letter-spacing: 1px;
  line-height: 42px;
  padding: 0 25px;
  transition: all 0.3s ease-in-out 0s;
  width: inherit;
  cursor: pointer
}
.spr-button.spr-button-primary:hover, .spr-button.spr-button-primary:focus {
  background-color: #76b4fa;
  border: none;
  color: #fff;
}
span.spr-starrating.spr-summary-starrating {
  float: left;
}
.spr-reviews {
  text-align: left;
}
.product-description-review i.spr-icon {
  font-size: 100%;
}
.product-description-review .spr-review-content-body {margin: 0 !important;}
/* Review App End */
/* Swatch Color Start */
.swatch-element > input, .wrapper_none .selector-wrapper {
  display: none;
}
.swatch-element label {
  color: #242424;
  font-size: 13px;
  font-weight: 400;
  text-transform: unset;
  float: left;
  margin-right: 20px;
  font-family: 'Rubik';
}
.swatch-element.color > label {
  position: relative;
  border: 1px solid #fff;
  outline: 1px solid transparent;
  border-radius: 50%;
  display: block;
  float: left;
  width: 21px;
  height: 21px;
  margin-right: 10px;
  padding: 1px;
  transition: all 0.3s ease 0s;
}
.swatch-element.color input:checked + label::before {
  content: "\f122";
  position: absolute;
  font-family: "Ionicons";
  color: #fff;
  font-size: 12px;
  left: 1px;
  right: 0;
  text-align: center;
  top: 1px;
  z-index: 99;
}
.swatch-element input:checked + label {
  color: #f1ac06;
}
/* Swatch Color End */
/* List Style Inside Start */
.tab-content ul, .tab-content ol,
.tab-content ul li, .tab-content ol li,
.single_product ul, .single_product ol,
.single_product ul li, .single_product ol li,
#exampleModal ul, #exampleModal ul li, #exampleModal li,
#exampleModal ol {
  list-style: inside;
}

/* List Style Inside End */
/* Create Page and custom tab */
.create-page h1, .create-page h2, .create-page h3, .create-page h4, .create-page h5, .create-page h6, .create-page img, .pro__tab__content__inner h1, 
.pro__tab__content__inner h2, .pro__tab__content__inner h3, .pro__tab__content__inner h4, .pro__tab__content__inner h5, .pro__tab__content__inner h6, 
.pro__tab__content__inner img {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 17px;
  text-transform: capitalize;
}
.create-page p{
  margin-bottom: 15px;
  color: #666666;
  font-size: 14px;
  line-height: 27px;
}
.create-page ul {
  padding-left: 15px;
  margin-bottom: 15px;
  list-style: inside;
}
.create-page ul li {
  color: #666666;
  font-size: 14px;
  line-height: 27px;
  list-style: inside !important;
  padding-left: 15px;
}
.create-page p:last-child {
  margin-bottom: 0;
}
/* End Create Page */
.lang-curr-dropdown ul li a i {
  margin-left: 0;
  position: relative;
  top: -2px;
}
.cart_empty_title {
  display: none;
}
.shopping-cart-title > h4 a:hover, .shopping-cart-total > h4 span.shop-total {
  color: #f1ac06;
}
.shopping-cart-delete > a:hover {
  border: 2px solid #f1ac06;
  color: #f1ac06;
}
.btn-style:hover {
  background-color: #f1ac06;
  border: 2px solid #f1ac06;
}
.main-menu.main-border nav > ul > li:last-child > a:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
}
.header-middle-bg-img .middle-same {
  margin-left: 0;
}
.devita_ptb_40 {padding-top: 40px; padding-bottom: 40px;}
.devita_plr_40 {padding-left: 40px; padding-right: 40px;}
.footer-contact p, .opening-content p {
  color: #ddd;
  font-size: 14px;
  margin: 0 0 8px;
}
.shop-service-wrapper [class*="col"]:last-child .shop-service-content::before, 
.shop-service-wrapper [class*="col"]:last-child .shop-service-content-3::before {
  display: none;
}
.cycle-blog-wrapper .blog-content > h4 a, .blog-content-4 > h4 a, .blog-wrapper h3 a, .blog-content-2 > h4 a, .blog-wrapper.main-blog h3 a,
.blog_temp .blog-content > h4 a {
  color: #242424 ;
}
.blog-content-4 > p, .blog-content > p, .blog-wrapper > p {
  color: #444;
}
.blog-wrapper-2.cycle-blog-wrapper .blog-meta ul li a, .blog-meta-4 ul li a, .blog-meta ul li a, .blog-content-2 .blog-meta ul li a, .main-blog .blog-meta ul li a,
.blog-content-2 .blog-meta ul li, .blog-meta-4 ul li, .blog-meta ul li {
  color: #444;
}
.cycle-blog-wrapper .blog-content > h4 a:hover, .blog-wrapper-2.cycle-blog-wrapper .blog-meta ul li a:hover, .blog-meta-4 ul li a:hover, .blog-content-4 > h4 a:hover,
.blog-wrapper h3 a:hover, .blog-meta ul li a:hover, .blog-readmore > a, .blog-content-2 .blog-meta ul li a:hover, .blog-content-2 > h4 a:hover,
.blog-wrapper.main-blog h3 a:hover, .main-blog .blog-meta ul li a:hover, .main-blog .blog-readmore > a, 
.header-middle-color-7 .shopping-cart-total > h4 span.shop-total, .header-middle-color-7 .shopping-cart-title > h4 a:hover,
.blog_temp .blog-content > h4:hover a{
  color: #f1ac06;
}
.header-middle-color-7 .shopping-cart-delete > a:hover {
  border: 2px solid #f1ac06;
  color: #f1ac06;;
}

.shopping-cart-title {
  width: 135px;
}
.shopping-cart-img {
  display: inline-block;
}
.shopping-cart-delete > a {
  line-height: 17px;
  width: 20px;
  height: 20px;
}
.product-img > span, .product-action > a.same-action:hover, .product-action > a.action-cart:hover, .zoompro-span > span {
  background-color: #f1ac06;
}
#scrollUp:hover {
  background: #f1ac06 none repeat scroll 0 0;
}
.qv_modal_body > div {
  display: inline-block;
  float: left;
}
.qwick-view-right {
  width: 45%;
}
.qwick-view-left {
  width: 48%;
  margin-right: 40px;
}
.qv_modal_dialog {
  max-width: 900px;
}
.quick-view-learg-img {
  border: 1px solid #ddd;
}
.modal .close {
  color: #a6a6a6;
  cursor: pointer;
  font-size: 25px;
  position: absolute;
  right: -35px;
  top: 10px;
}
.cart-plus-minus {
  flex: 0 0 30%;
  margin-right: 34px;
}
.quickview-btn-cart {
  flex: 0 0 50%;
}
.cart-plus-minus .cart-plus-minus-box {
  text-align: center;
  height: 50px;
  border: 2px solid #eeeeee;
  background: transparent;
}
.price-box__old > span, #ComparePrice > span {
  text-decoration: line-through;
}
.selector-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.selector-wrapper label {
  width: 90px;
}
.selector-wrapper select {
  border: 1px solid #eeeeee;
  top: -7px;
  background: rgba(0, 0, 0, 0) url(//www.tommyindustrial.com/cdn/shop/t/3/assets/arrow_down.png?v=168047650066337608451614421118) no-repeat scroll right 12px center;
  position: relative;
  padding-left: 12px;
  cursor: pointer;
  max-width: 224px;
}
.qv_modal_content {
  padding: 50px;
}
.quickview-btn-cart .btn-style {
  max-height: 50px;
}
.quickview-btn-cart .btn-style i {
  font-size: 25px;
}
/* Ajax Wishlist Start */
/* Ajax Wishlist Start */
.wish_action .added_wish, 
.wish_action .wish_wait, .wish_action.loading_wishlist .add_wish, .wish_action.loading_wishlist .added_wish {
  display: none;
}
.wish_action.loading_wishlist .wish_wait {
  display: inline-block;
}
.wish_action.is-active .added_wish {
  display: inline-block;
}
.wish_action.is-active .add_wish,
.wish_action.is-active.loading_wishlist .added_wish {
  display: none;
}
/* Ajax Wishlist End */
/* Product Grid Start */
.product-content > span, .product-list-content > span {
  color: #555555;
}
.product-content > h4 a, .product-list-content > h4 a {
  color: #000000;
}
.product-price-wrapper > span {
  color: #000000;
}
.product-price-wrapper > span.product-price-old {
  color: #000000;
}
.product-action-wrapper-2 > p, .best-selling-style-2 > p, .devita_product_action > p, .product-list-content > p {
  color: #000000;
}
.product-action > a.same-action, .product-action > a.action-cart, .best-pro-action-2 a {
  background-color: #eef0f1;
}
.product-action > a.same-action:hover, .product-action > a.action-cart:hover, .best-pro-action-2 a:hover {
  background-color: #f1ac06;
}
.product-img-slider .cr-navigation:hover {
  background: #f1ac06;
}
.nav-style.owl-carousel .owl-nav div:hover, .featured-product-active.owl-carousel > .owl-nav div:hover, .product-slider-nav.nav-style div:hover,
.product-slider-nav-2.nav-style div:hover, .nav-style-red.nav-style.owl-carousel .owl-nav div:hover, 
.product-slider-nav-2.nav-style.nav-style-red div:hover, .product-slider-nav.nav-style.nav-style-red div:hover,
.nav-style-goldenbell.nav-style.owl-carousel .owl-nav div:hover, .product-slider-nav.nav-style-goldenbell.nav-style div:hover, 
.product-slider-nav-2.nav-style-goldenbell.nav-style div:hover {
  background-color: #f1ac06;
}
.product-content > h4 a:hover, .pro-dec-feature li span, .sidebar-blog-content > h4 a:hover, .nav-style-3.owl-carousel > .owl-nav div:hover {
  color: #f1ac06;
}
.product-action > a, product-action > a span {
  color: #242424 !important;
}
.product-action > a:hover, product-action > a:hover span{
  color: #fff !important;
}
.timer span, .timer span p {
  color: #000000;
}
/* Product Grid End */
.section-bg-white, .product-slider-nav.nav-style, .product-slider-nav-2.nav-style, .product-slider {
  z-index: 1;
}
.position-relative > span {
  left: 20px;
  top: 0px;
  background-color: #f1ac06;
  border-radius: 3px;
  color: #fff;
  line-height: 1;
  padding: 4px 10px;
  position: absolute;
  z-index: 99;
  font-size: 10px;
}
.section-title-wrapper, .product-tab-list-2 {
  z-index: 1;
  position: relative;
}
.sidebar-social ul li {
  margin-right: 5px;
}
.view-mode a {
  cursor: pointer;
}
.view-mode a.active, .shop-catigory ul li:hover > a, .shop-catigory ul li:hover > a i, .sidebar-list-style ul li input:checked + a, .sidebar-list-style ul li a:hover {
  color: #f1ac06;
}
.shorting-style select {
  height: 35px;
}
.shop-tags a:hover, .shop-tags li.active a {
  border: 1px solid #f1ac06;
  background-color: #f1ac06;
  color: #fff;
}
.product_grid .row div[class^="col"] {
  padding-left: 0px;
  padding-right: 0px;
}
.product_grid .row {
  padding-left: 15px;
  padding-right: 15px;
}
.pagination-style li a:hover, .pagination-style li a.active, .pagination-style li a.next:hover {
  background-color: #f1ac06;
  color: #fff !important;
}
.devita-product-2 .product-action-wrapper-2 {
  height: 181px;
}

.subscribe-form-4 form .clear-3 input, .subscribe-form-5 form .clear-4 input, .nav-style-yellowgreen.nav-style.owl-carousel .owl-nav div:hover,
.product-slider-nav.nav-style.nav-style-yellowgreen div:hover {
  background-color: #f1ac06;
}
.product-overview-content h3::before {
  content: url(//www.tommyindustrial.com/cdn/shop/t/3/assets/shape-2.png?v=118442381867616906031614421143);
}
.shorting-style select {
  background: #fff url(//www.tommyindustrial.com/cdn/shop/t/3/assets/shop.png?v=34156301113202733881614421143) no-repeat scroll right 10px center;
}
.tax-select select {
  background: #fff url(//www.tommyindustrial.com/cdn/shop/t/3/assets/cart.png?v=65942827269532196131614421120) no-repeat scroll right 18px center;
}
.subscribe-form .mc-form .clear {
  background: rgb(255, 255, 255) url(//www.tommyindustrial.com/cdn/shop/t/3/assets/send.png?v=9166248677641543411614421142) no-repeat scroll right 25px center;
}
.subscribe-form .mc-form .clear:hover {
  background: rgb(221, 221, 221) url(//www.tommyindustrial.com/cdn/shop/t/3/assets/send.png?v=9166248677641543411614421142) no-repeat scroll right 25px center;
}
.subscribe-form-3 .mc-form .clear {
  background: rgba(0, 0, 0, 0) url(//www.tommyindustrial.com/cdn/shop/t/3/assets/send-2.png?v=48436938463896465831614421142) no-repeat scroll right 0 center;
}
.banner-content > h4::before {
  content: url(//www.tommyindustrial.com/cdn/shop/t/3/assets/shape.png?v=13549350649196571961614421143);
}
.sold_out, .disabled-add-to-cart {
  background-color: rgb(255, 0, 0, 1) !important;
  cursor: not-allowed !important;
  color: #fff !important;
}
.disabled {
  cursor: not-allowed !important;
}
.modal-body.qv_modal_body {
  padding: 0;
  display: block;
}
img.full-width {
  max-width: 380px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
.cl_desc {
  max-width: 650px;
  margin: 10px auto;
}
.position-relative.pro-dec-review::before {
  content: "";
  position: absolute;
  left: -17px;
  background-color: #e1e1e1;
  height: 20px;
  transition: all 0.4s ease 0s;
  width: 1px;
}
.action-cart, .swatch-element input, .swatch-element label, .lang-curr-dropdown ul li {
  cursor: pointer;
}
.prod_invent {
  padding-bottom: 20px;
}
.swatch {
  margin-bottom: 10px;
}
.quality-add-to-cart {
  padding: 30px 0 37px;
}
#ProductPrice, #ComparePrice span {
  color: #242424;
  font-size: 24px;
  font-weight: 500;
}
#ComparePrice span {
  font-size: 17px;
  margin-left: 10px;
}
.spr-icon.spr-icon-star, .spr-icon.spr-icon-star-half-alt {
  color: #f1ac06;
}
.description-review-area .spr-icon {
  font-size: 85%;
}
.product-details-img > span {
  background-color: #f1ac06;
}
.in-stock > p span, .product-dec-slider .product-dec-icon:hover, .pro-dec-categories li a:hover, .spr-summary-actions-newreview:hover,
.blog-dec-tags ul li a:hover, .blog-dec-social ul li a:hover {
  color: #f1ac06;
}
.description-review-topbar a.active {
  border-bottom: 2px solid #f1ac06;
}
.blog-meta ul li::before {
  right: -14px;
}
.pro-dec-feature {
  padding: 0px;
}
.shop-tags > ul > li > a.active {
  color: #fff;
  background: #f1ac06;
}
.errors ul li {
  margin-left: 15px;
  color: red;
}
.errors {
  border: 1px solid red;
  padding: 15px;
}
.blog-dec-tags ul li::after {
  top: 4px;
}
.blog-reply-wrapper form .text-leave input, .pro-dec-btn > a:hover, .cart-shiping-update > a:hover, .cart-clear > button:hover, 
.cart-clear > a:hover, button.cart-btn-2:hover, .grand-totall > a, .contact-form-style > button, .about-us-area .btn-style-2, 
.button-box button:hover, .devita-product-goldenbell .product-img > span {
  background-color: #f1ac06;
}
.breadcrumb-content li a+span::before {
  background-color: #aaaaaa;
  content: "";
  height: 2px;
  position: absolute;
  left: -22px;
  top: 8px;
  transform: rotate(-67deg);
  transition: all 0.4s ease 0s;
  width: 12px;
}
p.note.form-success {
  color: #ff6600;
  font-size: 18px;
}
.blog-meta ul li {
  font-size: 12px;
}
blockquote * {
  font-size: 19px;
  font-style: italic;
  font-weight: 500;
  margin: 0;
}
.grand-totall > h5 {
  margin: 36px 0 50px;
}
.p_qty_none > span {display: none;}
.contact-tab-list li a.active, .contact-tab-list li a:hover, .count-title h2, .about-testi .testimonial-rating i.theme-star-coffee,
.login-register-tab-list.nav a.active h4, .login-register-tab-list.nav a h4:hover, .login-toggle-btn>a:hover {
  color: #f1ac06;
}
.testimonial-active.about-testi-active .owl-dot.active {
  background: #f1ac06 none repeat scroll 0 0;
}
.about-us-area .btn-style-2:hover {
  background-color: #242424;
}
.login-toggle-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login-form-container.dev_address {
  box-shadow: none;
  padding: 0;
}
.dev_address select {
  border: 1px solid #ebebeb;
  color: #666;
  font-size: 14px;
  height: 50px;
  margin-bottom: 30px;
  padding: 0 15px;
  background: rgba(0, 0, 0, 0) url(//www.tommyindustrial.com/cdn/shop/t/3/assets/arrow_down.png?v=168047650066337608451614421118) no-repeat scroll right 20px center;
  cursor: pointer;
}
.dev_address_grid > div {
  width: 50%;
  display: inline-block;
  float: left;
}
.login-register-area .btn-style.btn-hover {
  cursor: pointer;
}
.rating_beforeafter_none .product-rating::before, .rating_beforeafter_none .product-rating::after {
  display: none;
}
.rating_beforeafter_none .product-action {
  margin: 0;
}
.btn-style-2 {
  background-color: #f1ac06;
  padding: 17px 37px 17px;
}
.btn-style-2:hover {
  background-color: #242424;
}
.news_pop .clear, .news_pop input.email {
  max-width: 300px;
  display: block;
  margin: 0 auto;
  border-radius: 3px;
}
.news_pop input.email {
  margin-bottom: 15px;
}
.button.btn-style-2 {
  border: none;
  cursor: pointer;
}
.add-to-cart > a {
  padding: 15px 0;
  text-align: center;
  min-width: 160px;
  background-color: #f1ac06;
}
.add-to-cart > a:hover {
  background-color: #242424;
}
.header-setting:hover button.icon-setting, .header-search-3 form button:hover, .single-setting-content2 li a:hover, 
.watch-cart button.icon-cart i.cart-down, .single-setting-content ul li a:hover {
  color: #f1ac06;
}
.watch-cart .btn-style:hover {
  background-color: #f1ac06;
  border: 2px solid #f1ac06;
}
.watch-cart button.icon-cart span.count-style, .nav-style.nav-style-watch.owl-carousel .owl-nav div:hover {
  background-color: #f1ac06;
}
watch-cart .shopping-cart-total > h4 span.shop-total, .watch-cart .shopping-cart-title > h4 a:hover, 
.header-middle-color-13 .shopping-cart-title > h4 a:hover, .header-middle-color-13 .shopping-cart-total > h4 span.shop-total, 
.header-middle-color-13.header-cart:hover button.icon-cart i.cart-bag, .watch-cart .shopping-cart-delete > a:hover,
.header-middle-color-13.header-cart:hover > button.icon-cart span.count-amount {
  color: #f1ac06;
}
.watch-header-top .lang-curr-dropdown ul li a i {
  color: #fff !important;
}
.sidebar-social-copyright {
  padding: 80px 40px 0 0;
}
.shop-topbar-wrapper {
  align-items: center;
}
.shop-topbar-left {
  display: flex;
  align-items: center;
}
.view-mode a {
  margin: 3px 10px 0 0;
}
.product-action > a.action-cart {
  padding: 9px 15px 8px;
}
.social-icon li a.instagram-outline {
  background-color: #c619b8;
  border: 2px solid #c619b8;
}
.social-icon li a.instagram-outline:hover {
  background-color: #fff;
  border: 2px solid #fff;
  color: #c619b8;
}
.social-icon li a.googleplus-outline {
  background-color: #ed3c32;
  border: 2px solid #ed3c32;
}
.social-icon li a.googleplus-outline:hover {
  background-color: #fff;
  border: 2px solid #fff;
  color: #ed3c32;
}
.social-icon li a.dribbble-outline {
  background-color: #c72982;
  border: 2px solid #c72982;
}
.social-icon li a.dribbble-outline:hover {
  background-color: #fff;
  border: 2px solid #fff;
  color: #c72982;
}
.social-icon li a.vimeo-outline {
  background-color: #82a418;
  border: 2px solid #82a418;
}
.social-icon li a.vimeo-outline:hover {
  background-color: #fff;
  border: 2px solid #fff;
  color: #82a418;
}
.social-icon li a.pinterest-outline {
  background-color: #c62a21;
  border: 2px solid #c62a21;
}
.social-icon li a.pinterest-outline:hover {
  background-color: #fff;
  border: 2px solid #fff;
  color: #c62a21;
}
.social-icon li a.skype-outline {
  background-color: #07a4c6;
  border: 2px solid #07a4c6;
}
.social-icon li a.skype-outline:hover {
  background-color: #fff;
  border: 2px solid #fff;
  color: #07a4c6;
}
.auto-parts-social.social-icon {
  margin-top: 4px;
}
.header-middle-color-15 .btn-style:hover, .header-middle-color-13 .btn-style:hover {
  background-color: #f1ac06;
  border: 2px solid #f1ac06;
}
.header-middle-color-15 .shopping-cart-delete > a:hover, .header-middle-color-13 .shopping-cart-delete > a:hover {
  border: 2px solid #f1ac06;
  color: #f1ac06;
}
.header-middle-color-15 .shopping-cart-title > h4 a:hover, .header-middle-color-15 .shopping-cart-total > h4 span.shop-total,
.header-top-red-3 .lang-curr-dropdown ul li ul li > a:hover, .header-top-red-3.header-top-color-2 .header-top-right ul li a:hover,
.furniture-best-selling .product-tab-list-2 a.active h4, .furniture-best-selling .product-tab-list-2.tab-list-red-3 a.active h4, 
.devita-product-red-3 .product-content > h4 a:hover, .devita-product-red-3 .product-rating i.theme-star {
  color: #f1ac06;
}
.item_container {
  height: 100%;
}


.body-bg.black-bg-3, .body-bg {
  background-color: #ffffff;
  
}

.services-center-text {
  text-align: unset;
}
.devita-product-2 .list-col .gridview {
  padding: 0px 5px 0px;
}
.product-tab-list-2 a {
  cursor: pointer;
  z-index: 99;
}
.language-currency {
  display: inline-block;
}
.language-currency::before {
  top: 0px;
}
/* Google Translate Start *//* Google Translate End */
.product-video a {
  background-color: #f1ac06;
}
.product-video a:hover {
  background-color: #242424;
}

/*-------- Magnific Popup CSS --------------*/
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
.mfp-preloader a {
  color: #CCC; }
.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }
.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
.mfp-arrow:active {
  margin-top: -54px; }
.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }
.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }
.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }
.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }
.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }
.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
  * Remove all paddings around the image on small screen
  */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.prod_video_parent {
  width: 100%;
  margin-top: 20px;
}
.product-details-slider-active.owl-carousel .owl-nav div:hover {
  border: 1px solid #f1ac06;
  color: #f1ac06;
}
.p_d_c.text-center .devi_prod_des p {
  margin: 0 auto;
  max-width: 60%;
  color: #242424;
}
.quality-add-to-cart .product-action {
  justify-content: flex-start;
}
.product-video.slide_p_video {
  margin-bottom: 30px;
}
.p_d_c.text-center .swatch-element {
  display: inline-block;
}
.p_d_c.text-center .rating-review {
  justify-content: center;
}
.qulaity_cart .product-variant-option {
  display: inline-block;
}
.qulaity_cart .selector-wrapper label {
  width: 210px;
}
.timer_margin {
  margin: 20px -25px;
}
.devita_product_action {
  width: 100%;
  top: 40%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  padding-bottom: 20px;
  border-radius: 0px 0px 4px 4px;  
  background: #fff;
}
.devita-product-2:hover .devita_product_action {
  top: 96%;
  opacity: 1;
  visibility: visible;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  z-index: 999;
}
.devita-product-2 {
  padding-bottom: 30px;
  margin: 0 4px;
}
.devita-product-2:hover {
  z-index: 999;
}
.banner-hover::before, .banner-hover::after {
  z-index: 0;
}
.devita_product_action > p {
  font-size: 12px;
  line-height: 18px;
  margin: 9px 10px 17px;
}
.product-action {
  margin: 0;
}
.product-rating::before {
  left: 10px;
}
.product-rating::after {
  right: 10px;
}
.fp_g_2.position-relative > span {
  left: 0px;
}
.best-selling-pro-wrapper {
  border-radius: 0;
}
.nav-style.nav-center.nav-style-watch.owl-carousel .owl-nav div {
  bottom: 230px;
}
.section-title {
  z-index: 1 !important;
  position: relative;
}
.shop-page-area .devita-product-2 {
  margin: 10px 4px 0px;
}
.product-details-content .swatch .header {
  margin-bottom: 10px;
}.description-review-area .tab-pane {
  padding: 0 30px 20px;
}
.product-dec-slider-2 .product-dec-icon:hover, .contact-information > p a:hover, .table-content table td.product-remove a:hover {
  color: #f1ac06;
}
.product_dynamic_btn {
  width: 55%;
}
.dynamic_btn_sticky .product_dynamic_btn {
  width: 50%;
}
.cart_box_wrapper .product_dynamic_btn {
  width: 100%;
}
.shopify-payment-button__button--hidden {
  display: none !important;
}
.prod_slider .swatch {
  max-width: 50%;
  margin: 0 auto;
}
/* Typography */




@font-face {
  font-family: Asap;
  font-weight: 400;
  font-style: normal;
  src: url("//www.tommyindustrial.com/cdn/fonts/asap/asap_n4.bbd5a7069d8fda6c6087204fae2b7f4dca87bf11.woff2?h1=dG9tbXlpbmR1c3RyaWFsLmNvbQ&h2=aXJvbndvcmtlcmxsYy5jb20&h3=aXJvbndvcmtlcnNsbGMuY29t&h4=dG9tbXlpbmR1c3RpcmFsLmNvbQ&h5=aXJvbndvcmtlcmluYy5jb20&h6=aXJvbndvcmtlci1sbGMuYWNjb3VudC5teXNob3BpZnkuY29t&hmac=7f833a108ffb6784485b14d6ece70a51d8b4f78eb96c28feb052006f8b95f56c") format("woff2"),
       url("//www.tommyindustrial.com/cdn/fonts/asap/asap_n4.b026277a6ffe301172d58b44f76f4a17a1fcac3a.woff?h1=dG9tbXlpbmR1c3RyaWFsLmNvbQ&h2=aXJvbndvcmtlcmxsYy5jb20&h3=aXJvbndvcmtlcnNsbGMuY29t&h4=dG9tbXlpbmR1c3RpcmFsLmNvbQ&h5=aXJvbndvcmtlcmluYy5jb20&h6=aXJvbndvcmtlci1sbGMuYWNjb3VudC5teXNob3BpZnkuY29t&hmac=8eb75b03a75a97a0238b0a793a3eab7c9e282892c9a4ba0377ee36ea2196fa0d") format("woff");
}

@font-face {
  font-family: Asap;
  font-weight: 400;
  font-style: normal;
  src: url("//www.tommyindustrial.com/cdn/fonts/asap/asap_n4.bbd5a7069d8fda6c6087204fae2b7f4dca87bf11.woff2?h1=dG9tbXlpbmR1c3RyaWFsLmNvbQ&h2=aXJvbndvcmtlcmxsYy5jb20&h3=aXJvbndvcmtlcnNsbGMuY29t&h4=dG9tbXlpbmR1c3RpcmFsLmNvbQ&h5=aXJvbndvcmtlcmluYy5jb20&h6=aXJvbndvcmtlci1sbGMuYWNjb3VudC5teXNob3BpZnkuY29t&hmac=7f833a108ffb6784485b14d6ece70a51d8b4f78eb96c28feb052006f8b95f56c") format("woff2"),
       url("//www.tommyindustrial.com/cdn/fonts/asap/asap_n4.b026277a6ffe301172d58b44f76f4a17a1fcac3a.woff?h1=dG9tbXlpbmR1c3RyaWFsLmNvbQ&h2=aXJvbndvcmtlcmxsYy5jb20&h3=aXJvbndvcmtlcnNsbGMuY29t&h4=dG9tbXlpbmR1c3RpcmFsLmNvbQ&h5=aXJvbndvcmtlcmluYy5jb20&h6=aXJvbndvcmtlci1sbGMuYWNjb3VudC5teXNob3BpZnkuY29t&hmac=8eb75b03a75a97a0238b0a793a3eab7c9e282892c9a4ba0377ee36ea2196fa0d") format("woff");
}





h1, h2, h3, h4, h5, h6, .main-menu ul li a, .product-tab-list-3 a h4, .shopping-cart-title > h4, .shopping-cart-title > h6, .overview-content-2 > h2,
.product-content > h4, .footer-title > h4, .product-tab-list-2 a h4, .opening-time > h4, .shopping-cart-total > h4, .shop-service-content > h4, 
.cycle-section-title .section-title, .section-title, .newsletter-title > h4, .product-action-wrapper-2 > p, .best-selling-style-2 > p, 
.devita_product_action > p, .product-list-content > p, .product-tab-list-2 a h4 {
  font-family: Asap, sans-serif;
  font-style: normal;
}



html, body, input, textarea, button, select, p, .goog-te-gadget-simple .goog-te-menu-value span, .swatch-element label, .header-contact-content > p,
.footer-about > p, .footer-contact p, .opening-content p, .copyright > p, .shop-topbar-left > p, .total-pages > p, .product-list-content > p,
.prod_invent p, .prod_invent span, .devi_prod_des p, .product-description-wrapper > p, .shopify-payment-button__button, .blog-categori > span,
.description-review-wrapper p, .blog-content-4 > p, .blog-content > p, .blog-wrapper > p, .single-blog-wrapper > p, .login-register-area p,
.overview-content-2 p, .contact-information > p, .wishlist-page p, .tax-wrapper > p, .discount-code > p, p#shipping-rates-feedback,
.cl_desc p, .product-content p, .overview-content-2 > p.peragraph-blog, .product-action-wrapper-2 > p, .best-selling-style-2 > p, 
.devita_product_action > p, .product-list-content > p, .shop-service-content > p, .footer-newsletter > p, .blog-meta ul li {
  font-family: Asap, sans-serif;
}




.notification-content p {
  font-family: -apple-system, sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* End Typography */
.slider-content.slider-animated-1.trans_header {
  padding-top: 182px;
}
.slider-content p {
  max-width: 600px;
}
.cycle-section-title .section-title, .section-title {
  text-transform: unset;
}
.owl-item.active .animated_left_1 {
  -webkit-animation-delay: 0.7s !important;
  animation-delay: 0.7s !important;
  -webkit-animation-name: fadeInLeft !important;
  animation-name: fadeInLeft !important;
}
.owl-item.active .animated_left_2 {
  -webkit-animation-delay: 1.0s !important;
  animation-delay: 1.0s !important;
  -webkit-animation-name: fadeInLeft !important;
  animation-name: fadeInLeft !important;
}
.owl-item.active .animated_left_3 {
  -webkit-animation-delay: 1.3s !important;
  animation-delay: 1.3s !important;
  -webkit-animation-name: fadeInLeft !important;
  animation-name: fadeInLeft !important;
}
.owl-item.active .animated_right_1 {
  -webkit-animation-delay: 0.7s !important;
  animation-delay: 0.7s !important;
  -webkit-animation-name: fadeInRight !important;
  animation-name: fadeInRight !important;
}
.owl-item.active .animated_right_2 {
  -webkit-animation-delay: 1.0s !important;
  animation-delay: 1.0s !important;
  -webkit-animation-name: fadeInRight !important;
  animation-name: fadeInRight !important;
}
.owl-item.active .animated_right_3 {
  -webkit-animation-delay: 1.3s !important;
  animation-delay: 1.3s !important;
  -webkit-animation-name: fadeInRight !important;
  animation-name: fadeInRight !important;
}
.owl-item.active .animated_right_4 {
  -webkit-animation-delay: 1.6s !important;
  animation-delay: 1.6s !important;
  -webkit-animation-name: fadeInRight !important;
  animation-name: fadeInRight !important;
}

.owl-item.active .animated_center_1 {
  -webkit-animation-delay: 0.7s !important;
  animation-delay: 0.7s !important;
  -webkit-animation-name: fadeInUp !important;
  animation-name: fadeInUp !important;
}

.owl-item.active .animated_center_2 {
  -webkit-animation-delay: 1.0s !important;
  animation-delay: 1.0s !important;
  -webkit-animation-name: fadeInUp !important;
  animation-name: fadeInUp !important;
}
.owl-item.active .animated_center_3 {
  -webkit-animation-delay: 1.3s !important;
  animation-delay: 1.3s !important;
  -webkit-animation-name: fadeInUp !important;
  animation-name: fadeInUp !important;
}
.banner-negative-mrg {
  position: relative;
  z-index: 9;
}
/* owl problem solution start */
.devita_owl_area .owl-carousel {
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  width: auto;
  overflow: hidden;
}
.devita_owl_area .owl-carousel::before, .devita_owl_area .owl-carousel::after {
  position: absolute;
  top: 0;
  height: 100%;
  content: "";
  width: 7px;
  left: 0;
  right: auto;
  z-index: 9;
}
.devita_owl_area .owl-carousel::after {
  left: auto;
  right: 0;
}
.owl-carousel.hover {
  padding-bottom: 170px;
  margin-bottom: -170px;
}
.owl-carousel.hover .owl-stage-outer {
  overflow: visible;
}
.nav_center.owl-carousel .owl-nav div {
  position: absolute;
  top: 50% !important;
  left: 10px;
  transform: translateY(-50%);  
  background-color: #efefef;
  border-radius: 3px;
  color: #242424;
  display: inline-block;
  font-size: 16px;
  height: 35px;
  line-height: 35px;
  opacity: 1;
  text-align: center;
  width: 35px;
  cursor: pointer;
  z-index: 11;
}
.devita_owl_area:hover .nav_center.owl-carousel .owl-nav div {
  margin-top: -85px;
}
.nav_center.owl-carousel .owl-nav div.owl-next {
  left: auto;
  right: 10px;
}
/* owl problem solution end */
.owl_nav_none .owl-nav {display: none !important;}


/*Landign Page Start */
.h-100vh {height: 100vh;}
.product_rating i {
  opacity: 1 !important;
  margin-bottom: 20px;
  display: inline-block;
  font-size: 12px !important;
  margin-right: 10px !important;
}
.price_old {
  text-decoration: line-through;
  margin-left: 10px;
  font-size: 16px;
}
.price_new {
  font-size: 25px;
  font-weight: 500;
}
.prod_details p {
  font-size: 16px;
  line-height: 25px;
}
.prod_details h4 a {
  font-size: 40px;
  color: #242424;
  font-weight: 500;
}
.prod_details h4 a:hover {
  color: #f1ac06;
}
.btn-primary, .product_act form input {
  font-size: 15px;
  padding: 10px 30px;
  box-shadow: none;
  outline: none;
  transition: all 0.3s ease;    max-width: 300px;
  height: 40px;
  line-height: 19px;
  text-align: center;
}
.btn-primary:hover, .btn-primary:focus, .product_act form input:hover {
  box-shadow: none;
  outline: none;
}
.vs_subtitle {
  max-width: 650px;
  margin: 0 auto;
}

.landing-contact-form input, .landing-contact-form textarea, .landing-contact-form input:focus, .landing-contact-form textarea:focus {
  background: transparent;
  border: none;
  border-bottom: 1px solid #999;
  padding-left: 0px;
  margin-bottom: 5px;
  width: 300px;
  box-shadow: none;
  outline: none;
  height: unset;
}
.buy_btn input {
  height: unset;
}
p.note.form-success.form-messege {
  padding: 0;
  font-size: 15px;
  color: #f1ac06;
}
/*Landign Page End */
.p_content_title::before, .p_content_title::after {
  content: "";
  width: 95px;
  height: 2px;
  position: absolute;
}
.p_content_title::before {
  top: -15px;
  left: 0;
}
.p_content_title::after {
  bottom: -10px;
  left: 95px;
}
.p_content_2 {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 4px rgba(217, 217, 217, 0.3);
}
.p_content_2:before {
  content: "";
  position: absolute;
  background: #f7f8f9;
  border-radius: 10px;
  top: 30px;
  left: -30px;
  right: 0;
  width: 120%;
  height: 75%;
  z-index: -1;
  opacity: 0.8;
}
.p_content_wrapper {
  padding: 0 30px;
}
.product_act.p_act_2 {
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}
.product-img > span {
  font-size: 14px;
}
.position-relative.p_list_view > span {
  left: 5px;
  top: 5px;
}
.breadcrumb-content li {
  margin-right: 21px;
}
.breadcrumb-content li::before {
  right: -18px;
}
.breadcrumb-content li a + span {
  margin-left: 25px;
}
.dev_address_grid input, .dev_address_grid select {
  background: transparent;
  border: 1px solid #ddd;
  height: 45px;
}
.my-account-checkbox {
  display: flex;
  align-items: center;
}
.my-account-checkbox input, .my-account-checkbox label {
  margin-bottom: 0;
}
.my-account-checkbox input[type="checkbox"] {
  margin-right: 8px;
}
.landing_subtitle {
  max-width: 500px;
  margin: 0 auto;
}
.p_temp .product-dec-slider a.slick-active:before, .p_temp .product-dec-slider-2 a.slick-active:before {
  opacity: 0;
}
.shop-widget .header-search-2 form button:hover {
  color: #f1ac06;
}

.is-affixed .inner-wrapper-sticky {
  padding-left: 0px;
  padding-right: 0px;
}
.single-product-bundle .position-relative > span {
  left: 0;
}
.header-setting.mobile_view, .watch_lang .language-currency::before {
  display: none;
}
.best_foul {
  z-index: 1;
  position: relative;
}
a.disabled-add-to-cart.sold_out, a.disabled-add-to-cart.remove_href.action-cart {
  color: #fff !important;
}
.mean-container a.meanmenu-reveal {
  background: #EDEDED;
}
.lang-curr-dropdown ul li a i {
  font-size: 10px !important;
}
.swatch-element.color.white > label {
  border: 1px solid #ddd;
}
.swatch-element.color.white input:checked + label::before {
  color: #000;
}
.nav-style.nav-style-limegreen.owl-carousel .owl-nav div:hover {
  background-color: #f1ac06;
}
.dev_pro_owl .owl-carousel:hover {
  z-index: 999;
  overflow-x: hidden;
}
.blog-categori > span {
  padding: 5px 13px;
}
#landing-page #google_translate_element {
  display: none !important;
}
.logo.logo-mrg {
  margin-top: 0;
}
.notification-padding-1 {
  padding: 10px 0;
}
.quality {
  margin: 0 3px 0 0;
}
.subscribe-form-2 form .clear-2 input {
  padding: 17px 46px 14px;
}
.social-icon li a {
  line-height: 38px;
}
.shopping-cart-content {
  max-height: 500px;
  overflow: auto;
}
.main-menu nav > ul > li > ul.mega-menu {
  background-color: rgba(255, 255, 255, 1);
}
#shipping-calculator #wrapper-response {
  margin-top: 20px;
}
.mean-container a.meanmenu-reveal i.fa.fa-close {
  position: relative;
  top: -2px;
}
.product-tab-list-3 {
  z-index: 1;
}
.sidebar-list-style ul li a.active {
  color: #f1ac06;
}
.product_nav2 > div {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #efefef;
  border-radius: 3px;
  color: #242424;
  display: inline-block;
  font-size: 16px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  opacity: 1;
  text-align: center;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  z-index: 9999;
}
.product_nav2 > div.owl-next {
  right: 0;
  left: auto;
}
.product_nav2 > div:hover {
  background-color: #f1ac06;
  color: #fff;
}
.sidebar-left-wrapper {
  z-index: 99999;
}
.watch-mean-menu .mean-container a.meanmenu-reveal {
  background: transparent;
}


.product-tab-content .tab-pane.active {
  padding-top: 50px;
  margin-top: -50px;
}

.product-tab-content .tab-pane.hover {
  padding-bottom: 170px;
  margin-bottom: -170px;
  overflow-X: hidden !important;
}

.product-tab-content .product-slider .owl-stage-outer {
    margin-top: 0;
    padding: 0;
    transform: translateY(0);
}


/* snow effect css  */

@keyframes snow {
 0% {
  background-position:0 0,0 0,0 0
 }
 100% {
  background-position:500px 1000px,400px 400px,300px 300px
 }
}
.devita_ice_effect {
 background-image:url(//cdn.shopify.com/s/files/1/0070/1107/4137/files/ice01.png?323),url(//cdn.shopify.com/s/files/1/0070/1107/4137/files/ice02.png?323);
 animation:snow 20s linear infinite;
}
.devita-effect-body {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 0;
	background-color: rgba(0,0,0,0);
}




.notification-content p {
  font-size: 21px;
}
.notification-content p a {
  position: relative;
  z-index: 9999;
  margin-left: 15px;
}
.notification-content p a:hover{
  color:#fff !important;
  border-color: #fff !important;
}
.notification-icon button {
	color: #fff;
	position: relative;
	z-index: 999;
}
.slider-content .slider-btn.mt-75 {
	margin-top: 30px;
}

.sidebar-list-style ul li {
	display: flex;
}

/* sidebar cat menu  */

.category-sub-menu ul li.has-sub > a {
  position: relative;
  display: block;
  cursor: pointer;
}
.category-sub-menu ul li.has-sub > a:after {
  content: "+";
  position: absolute;
  right: 5px;
}
.category-sub-menu ul li.has-sub.open {
  display: block;
  width: auto;
}
.category-sub-menu ul li.has-sub.open > a:after {
  content: "-";
}
.category-sub-menu ul li.has-sub li:last-child a {
  border: none;
}
.category-sub-menu ul li ul {
  display: none;
  margin-bottom: 15px;
}
.shop-menus .shop-categories {
	list-style: none;
	margin: 0;
	padding: 0;
}
.shop-menus .shop-categories li > ul {
	list-style: none;
	padding-left: 8px;
	margin: 0;
}
.shop-menus.category-sub-menu {
	border-top: 1px solid #efefef;
	padding-top: 25px;
	margin-top: 15px;
}
.shop-menus .shop-categories li {
	padding-bottom: 11px;
}
.shop-menus .shop-categories li > ul li:first-child {
	padding-top: 13px;
}
.fixed_slider_N .slider-banner > p {
	width: auto;
}






























































